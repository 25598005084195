import api from '../../utils/api'

// 获取详情
export async function pay_detail (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         insurance_pay_total: 5000,
  //         thirdparty_pay_total: 6000,
  //         left_time: 60,
  //         order_pay_total: 8000,
  //         order_examine_label: '血常规等24项检查',
  //         order_goods_label: '力度伸等2个商品',
  //         payable_amount_insurance: 2000,
  //         payable_amount_self: 3000,
  //         payable_amount_thirdparty: 6000,
  //         payments_self: [1],
  //         completed_insurance: false,
  //         completed_thirdparty: false,
  //         payments_thirdparty: [1]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/order_groups/${payload}/payments`)
}

// 获取院内签名
export async function insurance_sign (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         app_id: 'aasdsdsad',
  //         time_stamp: 'aasdsdsad',
  //         nonce_str: 'aasdsdsad',
  //         prepay_id: 'aasdsdsad',
  //         sign_type: 'aasdsdsad',
  //         pay_sign: 'aasdsdsad',
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/payments/wxpay/order_groups/${payload}/insurance/signs`)
}

// 获取第三方签名
export async function thirdparty_sign (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         app_id: 'aasdsdsad',
  //         time_stamp: 'aasdsdsad',
  //         nonce_str: 'aasdsdsad',
  //         prepay_id: 'aasdsdsad',
  //         sign_type: 'aasdsdsad',
  //         pay_sign: 'aasdsdsad',
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/payments/wxpay/order_groups/${payload}/thirdparty/signs`)
}

// 医保支付完成通知
export async function insurance_state (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/payments/insurance/order_groups/${payload}/state`)
}



export async function pay_order (payload) {
  return api.get(`/api/v1/mp/order_groups/${payload}/pay`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}
