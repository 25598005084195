<template>
  <div class="g_page_box page_box" style="background: #f2f7f8">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <Steps class="steps" :index="step_index" :list="step_list" />
      <div class="surplus_time" v-if="[1, 2].includes(pay_type)">
        支付剩余时间
        <van-count-down
          class="time"
          :time="data.left_time * 1000"
          @finish="finish"
        />
      </div>
      <!-- 院内支付 -->
      <template v-if="pay_type === 1">
        <div class="current_price">
          <label>¥</label>{{ format_money(data.insurance_pay_total) }}
        </div>
        <div class="all_price">
          总金额: ¥{{
            format_money(data.insurance_pay_total + data.thirdparty_pay_total)
          }}
        </div>
        <div class="product_names">
          <img src="../../assets/img/shop_cart.png" class="icon" />{{
            data.order_examine_label
          }}
        </div>
        <div class="card_box">
          <div class="card">
            <img
              src="../../assets/img/yibao.png"
              class="icon"
              width="25"
              height="14"
            />
            <div class="content">
              <div class="title">医保卡</div>
              <div class="price">
                社保支付金额 ￥{{ format_money(data.payable_amount_insurance) }}
              </div>
            </div>
          </div>
        </div>
        <div class="card_box" v-if="data.payable_amount_self">
          <div class="intro">社保卡余额不足选择其他支付方式</div>
          <div class="card">
            <img
              src="../../assets/img/weixin.png"
              class="icon"
              width="20"
              height="20"
            />
            <div class="content">
              <div class="title">微信支付</div>
              <div class="price">
                支付剩余 ￥{{ format_money(data.payable_amount_self) }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 第三方支付 -->
      <template v-else-if="pay_type === 2">
        <div class="current_price">
          <label>¥</label>{{ format_money(data.thirdparty_pay_total) }}
        </div>
        <div class="all_price">
          总金额: ¥{{
            format_money(data.insurance_pay_total + data.thirdparty_pay_total)
          }}
        </div>
        <div class="product_names">
          <img src="../../assets/img/shop_cart.png" class="icon" />{{
            data.order_goods_label
          }}
        </div>
        <div class="card_box">
          <div class="card">
            <img
              src="../../assets/img/weixin.png"
              class="icon"
              width="20"
              height="20"
            />
            <div class="content">
              <div class="title">微信支付</div>
              <div class="price">
                支付剩余 ￥{{ format_money(data.payable_amount_thirdparty) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="bottom_box" v-if="[1, 2].includes(pay_type)">
      <div class="btn" @click="pay">支付</div>
    </div>
  </div>
</template>

<script>
import Steps from "../../components/Steps";
// import {
//   pay_detail,
//   insurance_sign,
//   thirdparty_sign,
//   insurance_state,
//   pay_order,
// } from "./service";

import {
  pay_detail,   
  pay_order,
} from "./service";
import { format_money } from "../../utils/format";
// import { weixin_pay } from "../../utils/weixin_pay";

export default {
  data() {
    return {
      loading: false,
      id: this.$route.query.id,
      data: {},
      step_list: [],
      step_index: 0,
      has_insurance_pay: false, // 有院内支付
      has_thirdparty_pay: false, // 有第三方支付
    };
  },
  computed: {
    pay_type() {
      return (
        this.step_list[this.step_index] && this.step_list[this.step_index].type
      );
    },
  },
  components: {
    Steps,
  },
  created() {
    this.get_detail();
  },
  methods: {
    format_money,
    async get_detail() {
      this.loading = true;
      try {
        const { data } = await pay_detail(this.id);
        if (data) {
          // 步骤
          if (data.insurance_pay_total) {
            this.has_insurance_pay = true;
            this.step_list.push({
              type: 1,
              html: `院内支付<br/>￥${format_money(data.insurance_pay_total)}`,
            });
          }
          if (data.thirdparty_pay_total) {
            this.has_thirdparty_pay = true;
            this.step_list.push({
              type: 2,
              html: `第三方支付<br/>￥${format_money(
                data.thirdparty_pay_total
              )}`,
            });
          }
          this.step_list.push({
            type: 3,
            html: `完成`,
          });
          // 当前步骤、支付方式
          if (!data.completed_insurance) {
            this.step_index = 0;
          } else if (!data.completed_thirdparty) {
            if (this.has_insurance_pay) {
              this.step_index = 1;
            } else {
              this.step_index = 0;
            }
          }

          this.data = data;
        }
      } finally {
        this.loading = false;
      }
    },
    finish() {
      this.$router.replace({ path: "/my-order", query: { order_state: 5 } });
    },
    // 支付
    async pay() {
      // 院内支付
      //alert('1')
      //console.log('..')
      // await pay_order(this.id);
      // this.$notify({ type: "success", message: "支付成功" });
      //let that = this;

      //return;

      await pay_order(this.id);
      this.$notify({ type: "success", message: "支付成功" });
      let that = this;
      setTimeout(() => {
        that.$router.push({
          path: "/pay-success",
          query: { id: that.id },
        });
      }, 500);

      // if (this.pay_type === 1) {
      //   if (this.data.payable_amount_self) {
      //     const { data } = await insurance_sign(this.id);
      //     const { type } = await weixin_pay(data);
      //     if (type === 2) {
      //       this.$router.push({ path: "/pay-cancel", query: { id: this.id } });
      //       return;
      //     }
      //   }
      //   await insurance_state(this.id);
      //   if (this.data.thirdparty_pay_total) {
      //     this.$notify({ type: "success", message: "院内支付成功！" });
      //     this.step_index = 1;
      //   } else {
      //     this.$router.replace({
      //       path: "/pay-success",
      //       query: { id: this.id },
      //     });
      //   }
      // } else {
      //   const { data } = await thirdparty_sign(this.id);

      //   const { type } = await weixin_pay(data);
      //   if (type === 2) {
      //     this.$router.push({ path: "/pay-cancel", query: { id: this.id } });
      //     return;
      //   }
      //   this.$router.replace({ path: "/pay-success", query: { id: this.id } });
      // }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box {
  background: #f7f7f7;
}
.main_content {
  padding: 0 16px;
}
.steps {
  width: 90%;
  margin: 27px auto 0;
}
.surplus_time {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
  margin-top: 33px;
  text-align: center;
  & .time {
    display: inline;
    color: #707070;
    font-size: 12px;
  }
}
.current_price {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  color: #000000;
  margin-top: 16px;
  text-align: center;
  & label {
    font-size: 14px;
  }
}
.all_price {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 5px;
  text-align: center;
}
.product_names {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
  margin-top: 50px;
  & .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-top: -2px;
  }
}
.card_box {
  margin-top: 18px;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02);
  opacity: 1;
  border-radius: 12px;
  & .intro {
    font-size: 14px;
    line-height: 20px;
    color: #242938;
    opacity: 0.38;
    padding: 14px 0 6px 19px;
  }
}
.card {
  padding: 15px 16px;
  display: flex;
  align-items: center;
  & .icon {
    margin-right: 10px;
  }
  & .content {
    flex: 1;
    & .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
    }
    & .price {
      font-size: 12px;
      line-height: 17px;
      color: #242938;
      margin-top: 2px;
    }
  }
}
.bottom_box {
  padding: 24px 13px;
  & .btn {
    width: 100%;
    background: #0088ff;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    &.disabled {
      background: #ccc;
    }
  }
}
</style>
