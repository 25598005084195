<template>
  <div class="steps_box">
    <div class="line_box" :style="{background: color}">
      <div class="line" :style="{width: line_width, background: selected_color}"></div>
    </div>
    <div class="item_box">
      <div
        v-for="(item, i) in list"
        :key="i"
        :style="{width: item_width}"
        class="item">
        <div
          :style="{background: i <= index ? selected_color : color}"
          class="dot"></div>
          <div
            :style="{color: i <= index ? selected_color : text_color}"
            class="text">
            <div v-html="item.html"></div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      default: 0,
      type: Number
    },
    list: {
      default: () => [],
      type: Array
    },
    color: {
      default: '#D9DBDE',
      type: String
    },
    text_color: {
      default: '#242938',
      type: String
    },
    selected_color: {
      default: '#0088FF',
      type: String
    },
  },
  data() {
    return {
    }
  },
  computed: {
    item_width () {
      let len = this.list.length
      if (len > 0) {
        return 100 / len + '%'
      }
      return 0
    },
    line_width () {
      let len = this.list.length
      if (len > 1) {
        return 100 / (len - 1) * this.index + '%'
      }
      return 0
    }
  }
}
</script>
<style scoped>
.steps_box {
  position: relative;
}
.line_box {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 7px;
  & .line {
    height: 100%;
    width: 0;
  }
}
.item_box {
  display: flex;
  & .item {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    &:first-child {
      text-align: left;
      transform: translate(-1px, 0);
    }
    &:last-child {
      text-align: right;
      transform: translate(1px, 0);
    }
  }
  & .dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
  }
  & .text {
    margin-top: 9px;
  }
}
</style>
